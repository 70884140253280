import "./styles.css";
import sformat from "string-format";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useCopyToClipboard, useLocalStorage } from "react-use";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Box,
  TextField,
  Stack
} from "@mui/material";
import readXlsxFile from "read-excel-file";

const tasyakuran = `Kepada *Yth.*
*{name}*
       - di Tempat.

_Assalamualaikum Wr Wb_

Dengan Rahmat dan Ridho Allah SWT, kami mengharap kehadiran *{name}* pada acara Tasyakuran Walimatul Hamli dan Walimatul Haji yang Insya Allah akan diselenggarakan pada :

Hari / Tanggal : *Sabtu, 13 Mei 2023*
Waktu.              : *Pukul 12.30 WIB (ba'da Dzuhur) s/d 15.00 WIB*
Tempat           : *Jalan Kemiri Kavling Blok F 17 no 3, Ciwaduk, Kec. Cilegon, Kota Cilegon, Banten 42415 (PT. Artapuri Digital Mediatama).*

*Silahkan klik link dibawah untuk membuka undangan:*
          🔗 https://www.empat-bulanan-ayu.affan.net?nama={query}

Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila *{name}* berkenan hadir untuk memberikan doa restu.

Atas kehadiran serta doa restu *{name}* kami ucapkan terima kasih.

_Wassalamualaikum Wr. Wb._

*Hormat Kami,*
*Affan Dhia Ardhiva, Ayu Indah Damayanti, dan Segenap Sekeluarga*`;

const tasyakuran2 = `Kepada *Yth.*
*{name}*
       - di Tempat.

_Assalamualaikum Wr Wb_

Dengan Rahmat dan Ridho Allah SWT, kami mengharap kehadiran *{name}* pada acara Tasyakuran Walimatul Hamli dan Walimatul Haji.

*Silahkan klik link dibawah untuk membuka undangan:*
          🔗 https://www.empat-bulanan-ayu.affan.net?nama={query}

Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila *{name}* berkenan hadir untuk memberikan doa restu.

Atas kehadiran serta doa restu *{name}* kami ucapkan terima kasih.

_Wassalamualaikum Wr. Wb._

*Hormat Kami,*
*Affan Dhia Ardhiva, Ayu Indah Damayanti, dan Segenap Sekeluarga*`;

const vipText = `Kepada Yth. 
{name}
     - di Tempat

Kode VIP : {number}

Untuk Bapak/Ibu yang kami Hormati, kami mohon doa restunya pada Pernikahan Putra Putri kami yang InsyaAllah akan dilangsungkan besok, 27 November 2022 di Joglo Asri Sari Kuring Indah Cilegon. 

Bapak/Ibu Tamu VIP dapat langsung konfrimasi kehadiran ke WO/Keluarga yang standby di Pintu Masuk Gedung (memegang Papan Jalan), langsung dengan menyebutkan Kode VIP tersebut diatas.
_*Para Tamu VIP tidak perlu antri untuk mengisi di Buku Tamu*_

Setelah itu, Team WO akan mengarahkan Bapak/Ibu ke area pelaminan.

Terimakasih kami sampaikan atas Doa & Restu Bapak/Ibu 🙏🏼`;

const text = `Kepada Yth.
Bapak/Ibu/Saudara/i
*{name}*
      - di Tempat.

_Assalamualaikum Wr Wb_

Maha Suci Allah yang telah menciptakan mahluknya dengan berpasang-pasangan, ada langit dan bumi, siang dan malam, barat dan timur begitu juga dengan manusia yang di ciptakan berpasang-pasangan.

Dengan pesan singkat inilah Kami bermaksud mengundang Bapak/Ibu/Saudara/i dalam acara Pernikahan Putri dan Putra kami:

*Ayu Indah Damayanti, S.H., M.Kn. (Ayu) & Affan Dhia Ardhiva, S.Kom. (Dipa)*

Yang InsyaAllah akan dilaksanakan pada:
Hari / Tanggal : Minggu, 27 November 2022
Resepsi           : 11.00 - 14.30 WIB
Venue              : Joglo Sari Kuring Indah
                          Jl. Raya Cilegon Jakarta, RW. 02, Sukmajaya, Kec. Jombang, Kota Cilegon, Banten, 42416.

Silahkan klik link dibawah untuk info lebih lanjut acara pernikahan kami:
https://ayu-affan.inv.co.id/?to={query}

Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan untuk hadir dan memberikan doa restu.

_Wassalamualaikum Wr. Wb._

*Kami Yang Berbahagia,*
*Ayu dan Affan Sekeluarga.*

===========================

_Demi kenyamanan bersama, kami memohon agar para tamu undangan yang hadir berkenan menerapkan protokol kesehatan._`;

// Akad Nikah     : 09.00 WIB
// Joglo Sari Kuring Indah — https://goo.gl/maps/UFXUrnQ12ydRy6eh7

const waLink = `https://wa.me/{number}?text={param}`;
// const waLink = `https://api.whatsapp.com/send?phone={number}&text={param}`;

export interface Entry {
  text: string;
  link: string;
  name?: string;
  number?: string;
}

const readFile = async (file: any) => {
  if (!file) return undefined;

  const [header, ...rows] = await readXlsxFile(file);

  const getIdx = (str: string[]) => {
    let i;
    for (let j of str) {
      i = header.findIndex((i) => i === j);
      if (i !== -1) return i;
    }

    return null;
  };
  const queryIdx = getIdx(["Query"]);
  const nameIdx = getIdx(["Nama", "Name"]);
  const numberIdx = getIdx(["Nomor", "Number"]);

  const rowsFormat = rows
    .map((row) => {
      const number = row[numberIdx!]?.toString().replace(/[^\d]/g, "");
      const name = row[nameIdx!];
      const query =
        queryIdx === null ? name?.toString().replace(/\s/, "+") : row[queryIdx];

      const formatText = sformat(text, {
        name,
        query
      });

      const formatLink = number
        ? sformat(waLink, {
            number,
            param: encodeURIComponent(formatText)
          })
        : undefined;

      return {
        name,
        text: formatText,
        link: formatLink,
        number
      } as Entry;
    })
    .filter((i) => !!i.name)
    .filter((i) => !["Istri", "Pasangan"].includes(i.name || ""));

  return rowsFormat;
};

const readFileTasyakuran = (
  data: Array<string[]>,
  templateText = tasyakuran
) => {
  if (!data || !data.length) return undefined;

  const [header, ...rows] = data;

  const getIdx = (str: string[]) => {
    let i;
    for (let j of str) {
      i = header.findIndex((i) => i === j);
      if (i !== -1) return i;
    }

    return null;
  };
  const queryIdx = getIdx(["Query"]);
  const nameIdx = getIdx(["Nama", "Name"]);
  const numberIdx = getIdx(["Nomor", "Number"]);

  const rowsFormat = rows
    .map((row) => {
      const number = row[numberIdx!]?.toString().replace(/[^\d]/g, "");
      const name = row[nameIdx!];
      const query =
        queryIdx === null
          ? name?.toString().replace(/\s/g, "+")
          : row[queryIdx];

      const formatText = sformat(templateText, {
        name,
        query
      });

      const formatLink = number
        ? sformat(waLink, {
            number,
            param: encodeURIComponent(formatText)
          })
        : undefined;

      return {
        name,
        text: formatText,
        link: formatLink,
        number
      } as Entry;
    })
    .filter((i) => !!i.name)
    .filter((i) => !["Istri", "Pasangan"].includes(i.name || ""));

  return rowsFormat;
};

const readVIPFile = async (file: any) => {
  if (!file) return undefined;

  const [header, ...rows] = await readXlsxFile(file);

  const getIdx = (str: string[]) => {
    let i;
    for (let j of str) {
      i = header.findIndex((i) => i === j);
      if (i !== -1) return i;
    }

    return null;
  };
  const queryIdx = getIdx(["Query"]);
  const nameIdx = getIdx(["Nama", "Name"]);
  const numberIdx = getIdx(["Nomor", "Number"]);

  const rowsFormat = rows
    .map((row) => {
      const number = row[numberIdx!]?.toString().replace(/[^\d]/g, "");
      const name = row[nameIdx!];
      const query =
        queryIdx === null ? name?.toString().replace(/\s/, "+") : row[queryIdx];

      const formatText = sformat(vipText, {
        name,
        number
      });

      return {
        name,
        text: formatText,
        number
      } as Entry;
    })
    .filter((i) => !!i.name)
    .filter((i) => !["Istri", "Pasangan"].includes(i.name || ""));

  return rowsFormat;
};

export default function App() {
  const [rows, setRows] = useState<Partial<Entry>[] | undefined>();
  const [state, copyToClipboard] = useCopyToClipboard();
  const [templateText, setTemplateText] = useLocalStorage(
    "whatsapp-batch-text:text-template",
    tasyakuran
  );
  const [text, setText] = useLocalStorage(
    "whatsapp-batch-text:name-list:raw",
    "Nama;Nomor\n"
  );

  const handleChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    const value = event.target.value;
    setText(value);
  };

  useEffect(() => {
    const lines = text?.split("\n") || [];
    const result = lines.map((line: string) => {
      return line.split(";").map((i) => i.trim());
    });
    setRows(readFileTasyakuran(result, templateText));
  }, [text, templateText]);

  const loadData = async (file: any) => {
    const data = await readFileTasyakuran(file, templateText);

    setRows(data);
  };

  return (
    <div className="App">
      <Stack spacing={5}>
        <Button variant="contained" component="label">
          Upload
          <input
            hidden
            accept="*/*"
            type="file"
            onChange={(e) => {
              const file = e.target.files?.[0];

              if (file) {
                loadData(file);
              }
            }}
          />
        </Button>
        <TextField
          id="outlined-multiline-static"
          label="List Nama"
          multiline
          rows={4}
          value={text}
          variant="outlined"
          onChange={handleChange}
        />
        {rows?.map((i) => {
          return (
            <Card key={i.link} variant="outlined">
              <CardContent>
                <Box
                  sx={{
                    width: "100%",
                    "& a:visited .done": {
                      display: "block"
                    },
                    "& a:link .done": {
                      display: "none"
                    }
                  }}
                >
                  <span>{i.number}. </span>
                  {i.link ? (
                    <a href={i.link} target="_blank">
                      {i.name}
                      <span className="done"> DONE</span>
                    </a>
                  ) : (
                    i.name
                  )}
                </Box>
              </CardContent>
              <CardActions>
                {!!i.link && (
                  <Button onClick={() => i.link && copyToClipboard(i.link)}>
                    Copy Link
                  </Button>
                )}
                {!!i.text && (
                  <Button onClick={() => i.text && copyToClipboard(i.text)}>
                    Copy Text
                  </Button>
                )}
              </CardActions>
            </Card>
          );
        })}
        <TextField
          id="outlined-multiline-static"
          label="Text Template"
          multiline
          maxRows={10}
          value={templateText}
          variant="outlined"
          onChange={(e) => setTemplateText(e?.target?.value || "")}
        />

        <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
          <Button
            variant="contained"
            component="label"
            onClick={() => setTemplateText(tasyakuran)}
          >
            Reset Text Template
          </Button>
          <Button
            variant="contained"
            component="label"
            onClick={() => setTemplateText(tasyakuran2)}
          >
            Reset Text Template No Date
          </Button>
        </Box>
      </Stack>
    </div>
  );
}
